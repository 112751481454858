@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');

@import '~/styles/colors.scss';
@import '~/styles/typography.scss';

@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-typeahead/css/Typeahead.scss';
@import '~react-bootstrap-typeahead/css/Typeahead-bs4.scss';

:root {
  --redacted-selection: var(--orange);
  --hiago-primary: #6ccceb;
}

label {
  text-transform: uppercase;
  font-family: $headings-font-family;
  margin-bottom: 0.2rem;
}

// Typeahead control styling
.rbt {
  &-input-multi {
    padding: $input-btn-padding-y;
  }
  &-token {
    color: var(--primary);
    background-color: var(--primary-lightest);
    border-radius: 4px;
    &-active {
      color: $body-bg;
      background-color: var(--primary);
    }
    &-remove-button {
      top: 2px !important;
    }
  }
}

// Google maps
.pac-container {
  z-index: 1100;
}

// Bootstrap color overrides
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
  font-weight: bold;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='grey' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

a {
  color: var(--primary);
  &:hover {
    color: var(--primary-darker);
  }
}

.btn {
  border-radius: 9999px;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  &:hover {
    background-color: var(--primary-darker);
    border-color: var(--primary-darker);
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
  box-shadow: 0 0 2px 0.2rem var(--primary-transparent);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 2px 0.2rem var(--primary-transparent);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary-lighter);
  border-color: var(--primary-lighter);
}

.btn-link {
  color: var(--primary);
  &:hover {
    color: var(--primary-darker);
  }
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #ffffff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 2px 0.2rem var(--primary-transparent);
}

.tooltip {
  z-index: 1151 !important;
}

.badge-primary {
  background-color: var(--primary);
}

.page-link {
  color: var(--primary);
  &:hover {
    color: var(--primary-darker);
  }
}

.page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
}

input[type='date']::-webkit-clear-button {
  display: block;
}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group {
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;

  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    .input-group-text,
    .form-control {
      border-color: #80bdff;
    }
  }

  .form-control:focus {
    box-shadow: none;
  }

  &.is-valid {
    &:focus-within {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .input-group-text,
    .form-control {
      border-color: #28a745;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.is-invalid {
    &:focus-within {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    .input-group-text,
    .form-control {
      border-color: #dc3545;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  margin-inline-start: 4px;
}

.react-datepicker__close-icon {
  &:focus,
  &:active {
    outline: none;
  }
  &::after {
    background-color: var(--primary);
    font-size: 16px;
    padding: 0;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary);

  &:hover {
    background-color: var(--primary-darker);
  }
  &:focus,
  &:active {
    outline: none;
  }
}

.react-datepicker__triangle {
  left: 50% !important;
}

// style Bootstrap's Toast close button
.toast-header .close {
  opacity: 1;
  color: white;
  text-shadow: none;
}

.light-gray{
  color: #a8aaab;
}
