$theme-colors: (
  'icon-color': #8492a6,
);

$body-bg: #eff2f7;
$body-color: rgb(24, 24, 24);
$headings-color: var(--primary);
$navbar-light-color: var(--primary);

$text-color: #3c4858;

$primary: #2892b0;
$grey: #909090;
$greyLight: #6e6e6e;
$shade: #8492a6;
